import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loaders/loader1/loader.component';
import {ScrollTopComponent} from './scroll-top/scroll-top.component';
import { NavBar1Component } from './nav-bars/nav-bar1/nav-bar1.component';
import { SideBar1Component } from './side-bars/side-bar1/side-bar1.component';
import { Breadcrumb1Component } from './breadcrumbs/breadcrumb1/breadcrumb1.component';
import { IqCardComponent } from './cards/iq-card/iq-card.component';
import { ListStyle1Component } from './menus/list-style1/list-style1.component';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {RouterModule} from '@angular/router';
import { TabNavComponent } from './tabs/tab-nav/tab-nav.component';
import { TabContentComponent } from './tabs/tab-content/tab-content.component';
import { CardWithImageComponent } from './card-with-image/card-with-image.component';
import {HttpClient } from '@angular/common/http';
import {TranslateModule } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CookieService } from 'ngx-cookie-service';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
import { RightSideBarComponent } from './right-side-bars/right-side-bar/right-side-bar.component';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}


@NgModule({
  declarations: [
    LoaderComponent,
    ScrollTopComponent,
    NavBar1Component,
    SideBar1Component,
    Breadcrumb1Component,
    IqCardComponent,
    ListStyle1Component,
    TabNavComponent,
    TabContentComponent,
    CardWithImageComponent,
    RightSideBarComponent
  ],
  exports: [
    LoaderComponent,
    ScrollTopComponent,
    NavBar1Component,
    SideBar1Component,
    Breadcrumb1Component,
    IqCardComponent,
    ListStyle1Component,
    TabNavComponent,
    TabContentComponent,
    CardWithImageComponent,
    RightSideBarComponent
  ],
  imports: [
    CommonModule,
    NgScrollbarModule,
    RouterModule,
    TranslateModule,
    AlertModule,
    MatSlideToggleModule,
    FormsModule,
    MatSelectModule,
    MatOptionModule,
    MatFormFieldModule,
    MatIconModule,
    MatTooltipModule,
    MatInputModule, // Add this line
    MatAutocompleteModule // Add this line
  ],
  providers:[
    CookieService 
  ]
})
export class SofboxModule { }
