import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import * as momentTZ from 'moment-timezone';
const momentTZ = require('moment-timezone');
import { ISort } from '../models/common';
import { IPagination } from '../models/gallery';
import { ILeaveAndPermissionSelectPeriod } from '../Datasources/leave-and-permission-approval-datasource ';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  getValidationErrors(error: HttpErrorResponse) {
    const validationErorrs: string[] = [];
    if (error.error.data &&
      error.error.data.error &&
      error.error.data.error.code &&
      error.error.data.error.code === 'REQUIRED_PARAMETERS_MISSING_ERROR' &&
      error.error.data.error.details &&
      Array.isArray(error.error.data.error.details)) {
      for (const detail of error.error.data.error.details) {
        if (Array.isArray(detail)) {
          for (const message of detail) {
            if (typeof message === 'string') {
              validationErorrs.push(message);
            }
          }
        }
      }
    }
    return validationErorrs;
  }

  trimStringsInObject(target: object) {
    if (target === null || target === undefined || typeof target !== 'object') {
      return;
    }
    for (const key in target) {
      if (typeof target[key] === 'string') {
        target[key] = (target[key] as string).trim();
      }
    }
    return target;
  }

  generateListQueryParameters(pagination?: IPagination, sort?: ISort, search?: string, filters?: Map<string, string>,selectedPeriod?:ILeaveAndPermissionSelectPeriod): string {
    let queryParmas = '?';
    if (
      pagination &&
      Number.isInteger(pagination.pageOffset) &&
      Number.isInteger(pagination.recordLimit)
    ) {
      queryParmas += `pageOffset=${pagination.pageOffset}&recordLimit=${pagination.recordLimit}&`;
    }
    if (sort && sort.sortBy && sort.sortOrder) {
      queryParmas += `sortBy=${sort.sortBy}&sortOrder=${sort.sortOrder}&`;
    }

    if (search && search !== '') {
      queryParmas += `search=${search}&`;
    }

    if (filters && filters.size) {
      filters.forEach((value, key) => {
        if (value && value.length) {
          queryParmas += `${key}=${value}`;
          if (!queryParmas.endsWith('&')) {
            queryParmas += '&';
          }
        }
      });
    }
    if (selectedPeriod && selectedPeriod.fromDate && selectedPeriod.toDate) {
      queryParmas += `fromDate=${selectedPeriod.fromDate}&toDate=${selectedPeriod.toDate}&`;
    }

    if (queryParmas.endsWith('&')) {

      // Remove the '&' character from the end of the string
      return queryParmas.slice(0, -1);
    }

    // If there are no queries return empty string
    if (queryParmas === '?') {
      return '';
    }
    return queryParmas;
  }

  /**
   * Extracts the time in 24 hr format from the date object.
   * @param date A javascript date object.
   */
  getTimeFromDate(date: Date) {
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  /**
   * Converts Time to javascript date object.
   * @param time Time represented as a string in the format HH:mm.
   */
  getDateFromTime(time: string) {
    const splitTime = time.split(':');
    const hours = parseInt(splitTime[0], 10);
    const minutes = parseInt(splitTime[1], 10);
    const date = new Date();
    date.setUTCHours(hours);
    date.setUTCMinutes(minutes);
    return date;
  }

  extractDate(date: Date) {
    const year = date.getFullYear().toString();
    let month = (date.getMonth() + 1).toString();
    let day = (date.getDate()).toString();
    month = month.padStart(2, '0');
    day = day.padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  /**
   * Extracts the time in 24 hr format from the date object.
   * @param date A javascript date object.
   */
   getTimeFromDateWithConvertedTimezone(date: Date, timezone: string) {
    const convertedDate =  new Date(date.toLocaleString('en-US', { timeZone: timezone }));
    const hours = convertedDate.getUTCHours().toString().padStart(2, '0');
    const minutes = convertedDate.getUTCMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  convertTimetoUTC(time: string, timezone: string){
    const inputMoment = momentTZ.tz(time, "HH:mm:ss", timezone);
    const utcTime = inputMoment.clone().tz('UTC').format("HH:mm");
    return utcTime;
  }

  convertUTCTimetoTimezone(time: string, timezone: string): string {
    const inputMoment = momentTZ.tz(time, "HH:mm:ss", "UTC");
    return inputMoment.clone().tz(timezone).format("HH:mm");
  }
}
