import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class TimeZoneService {
  private timezoneStorageKey = 'timezone';
  private changedTimezoneStorageKey = 'newTimezone';
  updatedTimezone$: BehaviorSubject<string> = new BehaviorSubject(' ');
  updatedNewTimezone$: BehaviorSubject<string> = new BehaviorSubject(' ');

  constructor() {
    this.updatedTimezone$.next(this.getUserTimeZone());
    this.updatedNewTimezone$.next(this.getUserTimeZone());
  }

  setTimeZone(timeZoneName: string) {
    localStorage.setItem(this.timezoneStorageKey, timeZoneName);
    this.updatedTimezone$.next(this.getUserTimeZone());
  }

  setNewTimeZone(timeZoneName: string) {
    sessionStorage.setItem(this.changedTimezoneStorageKey, timeZoneName);
    this.updatedNewTimezone$.next(this.getUserTimeZone());
  }

  getUserTimeZone() {
    return localStorage.getItem(this.timezoneStorageKey);
  }

  getUserNewTimeZone() {
    return sessionStorage.getItem(this.changedTimezoneStorageKey);
  }

  convertTimeZone(time: Date) {
    const format = 'YYYY/MM/DD HH:mm:ss';
    const zone = this.getUserNewTimeZone() || this.getUserTimeZone();
    return zone ? moment(time, format).tz(zone).format(format) : time;
  }

  convertTimeZoneDate(time: Date): Date {
    const format = 'YYYY/MM/DD HH:mm:ss';
    const zone = this.getUserNewTimeZone() || this.getUserTimeZone();
    return zone ? new Date(moment(time, format).tz(zone).format(format)) : time;
  }

  getDisplayTimeZone() {
    const userTimeZone = this.getUserNewTimeZone() || this.getUserTimeZone() || moment.tz.guess();
    return 'GMT ' + moment().tz(userTimeZone).format('Z');
  }

  timeToDate(time: string, timezone: string): Date {
    return moment(time, 'HH:mm:ss').tz(timezone).toDate();
  }
}
