import { Pipe, PipeTransform } from '@angular/core';
import { TimeZoneService } from '../services/time-zone.service';

@Pipe({
  name: 'customTimezone',
  pure: false 
})
export class CustomTimezonePipe implements PipeTransform {
  constructor(
    private timezoneServ : TimeZoneService
  ){}
  transform(date: string, ...args: any[]): any {
    if (date && date !== '0000-00-00' && date !== '00:00:00') {
      const dateObj = new Date(date);
      if (!isNaN(dateObj.getTime())) {
        return this.timezoneServ.convertTimeZone(dateObj);
      }
    }
    return null; // or return the original date string if you prefer
  

  }

}
